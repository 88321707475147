import ScissorsIcon from 'assets/icons/scissors.png';
import HairColorIcon from 'assets/icons/hair-color-icon.png';
import HairBrushIcon from 'assets/icons/brush.png';
import StraightRazorIcon from 'assets/icons/straight-razor.png';
import { BookButton } from 'components/ui/book-button/book-button';
import { ServiceSection } from 'components/ui/service-section/service-section';
import './services.css';

const services = [
    {
        title: 'Cuts',
        firstIcon: ScissorsIcon,
        secondIcon: StraightRazorIcon,
        firstIconAlt: 'scissors icon',
        secondIconAlt: 'straight razor icon',
        services: [
            {
                service: 'Children under 8',
                price: '$35',
            },
            {
                service: 'Older child/Tween cut',
                price: '$55',
            },
            {
                service: 'Short Haircut',
                description: 'Sharp edges, mostly clipper cut/fade, scissor blend on top',
                price: 'Starting at $55',
            },
            {
                service: 'Short detailed cut',
                description: 'Soft and piecey, razor layers, blunt scissor cut, detailed texturizing, bobs, mulllets, pixies, bixies, you name it!',
                price: 'Starting at $75/90',
            },
            {
                service: 'Long haircut',
                description: 'Anything below the shoulders!',
                price: 'Starting at $75/90',
            },
        ]
    },
    {
        title: 'Color',
        firstIcon: HairColorIcon,
        secondIcon: HairColorIcon,
        firstIconAlt: 'hair color icon',
        secondIconAlt: 'hair color icon',
        services: [
            {
                service: 'Full Custom color',
                price: 'Starting at $250',
                description: 'Full head of foils or balayage, root shadow/blend, detail work, toner, bonding treatment'
            },
            {
                service: 'Partial custom color',
                price: 'Starting at $165',
                description: 'Partial foil or balayage , root shadow/blend, detail work, toner, bonding treatment'
            },
            {
                service: 'Root touch up/single process/grey coverage - Roots only',
                price: '$85',
            },
            {
                service: 'Root touch up/single process/grey coverage - Roots and gloss/bonder',
                price: '$135',
            },
            {
                service: 'Global blonding/full bleach and tone',
                price: '$150/hour',
            },
            {
                service: 'Global bonding root touchup',
                price: '$175',
            },
            {
                service: 'Color Correction',
                price: '$135/hour',
            }
        ]
    },
    {
        title: 'Other',
        firstIcon: HairBrushIcon,
        secondIcon: HairBrushIcon,
        firstIconAlt: 'scissors icon',
        secondIconAlt: 'scissors icon',
        services: [
            {
                service: 'Curl Cult Perm/ Body wave',
                price: '$250',
            },
            {
                service: 'Malibu Treatment/Mineral remover ',
                price: '$55',
            },
            {
                service: 'K18 treatment',
                price: '$40',
            },
            {
                service: 'Lasio Keratin or Brazilian Blowout',
                price: '$300',
            },
            {
                service: 'Blowout/Style',
                price: '$55+',
            },
            {
                service: 'Formal Style',
                price: '$100+',
            },
            {
                service: 'Extensions',
                description: 'Hand tied and machine weft',
                price: 'Priced Upon Consultation',
            },
            {
                service: 'Wash / treatment / blowout with extensions',
                price: '$65+',
            },
            {
                service: 'Facial Waxing',
                price: '$20+',
            }
        ]
    },
];

export const ServicesPage = () => {
    return (
        <div className='services-page-main'>
            <div className='services-container'>
                <div className="section-title">SERVICES</div>
                <BookButton />
                <div className='services-main-content'>
                    {services.map(service => {
                        return <ServiceSection
                            title={service.title}
                            services={service.services}
                            firstIcon={service.firstIcon}
                            firstIconAlt={service.firstIconAlt}
                            secondIcon={service.secondIcon}
                            secondIconAlt={service.secondIconAlt}
                        />
                    })}
                </div>
            </div>
        </div>
    );
}